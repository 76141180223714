import React from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyleGuide = () => (
  <div>
    <div className="bar-step">
      <Link to="#" className="checked">
        <span className="icon-car icon"></span>
        <span className="d-none d-md-block text">YOUR CAR</span>
      </Link>
      <Link to="#" className="active">
        <span className="icon-dealer-favorite icon"></span>
        <span className="d-none d-md-block text text-dealer-favorite">CHOOSE YOUR DEALER</span>
      </Link>
      <Link to="#" className="">
        <span className="icon-inbox icon"></span>
        <span className="d-none d-md-block text">ADD YOUR CONTACT</span>
      </Link>
    </div>
    <Layout>
      <SEO title="Style Guide" />

      <h1 className="with-border-top">Buttons</h1>

      <h2>Primary Button</h2>

      <h3 className="dark-red">Enabled</h3>

      <div className="mb-4">
        <Link to="/sessions/login" className="button red">Sign In</Link>
      </div>

      <h3><span className="dark-red">Disabled</span> (50% opacity)</h3>

      <div className="mb-4">
        <Link to="/sessions/login" className="button red disabled">Sign In</Link>
      </div>


      <h3 className="dark-red">Button with icon</h3>

      <div className="mb-4">
        <Link to="/sessions/login" className="button red with-icon">
          <span className="icon-download icon"></span>
          DOWNLOAD NOW</Link>
      </div>

      <h2>Secondary</h2>

      <div className="mb-4">
        <Link to="/sessions/signup" className="button white">Submit</Link>
      </div>


      <h1 className="with-border-top">Heading 1 with border</h1>

      <h1>Heading 1</h1>

      <h2>Heading 2</h2>

      <h3>Heading 3</h3>

      <h4>Heading 4</h4>


      <p>Regular text is top Please fill your email and password to get access to My Nissan.</p>

      <p className="small grey-border">Small text can be used for legal mention</p>

      <br />
      <br />


      <h1 className="with-border-top">UI Components</h1>

      <h3 className="dark-red">Switch / Simple 2 items Tab</h3>

      <div className="text-center mb-4">

        <ul className="nav nav-tabs nav-tabs-2-items d-inline-flex" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="nav-link" id="first-tab" data-toggle="tab" href="#first-tab-content" role="tab" aria-controls="next" aria-selected="true">NEXT EVENTS</a>
          </li>
          <li className="nav-item">
            <a className="nav-link active" id="second-tab" data-toggle="tab" href="#second-tab-content" role="tab" aria-controls="past" aria-selected="false">PAST EVENTS</a>
          </li>
        </ul>

      </div>

      <div className="white-container w-auto mb-4">

        <div className="tab-content">
          <div className="tab-pane" id="first-tab-content" role="tabpanel" aria-labelledby="first-tab">

            <span className="icon-help fs-30 mr-2 align-middle"></span> <a href="#" className="link-nav">View an example ></a><br />


          </div>
          <div className="tab-pane active" id="second-tab-content" role="tabpanel" aria-labelledby="second-tab">


            <span className="icon-marker fs-30 mr-2 align-middle"></span> <a href="#" className="link-nav dark-red"><strong>Use my position</strong></a>

            <h1 className="text-center">Congratulations!</h1>

            <div className="text-center">

              <span className="icon-win"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>

            </div>

            <h2 className="text-center">On the road to the <span className="bronze">BRONZE</span> level</h2>

          </div>
        </div>

      </div>



      <h3 className="dark-red">Accordions</h3>

      <div className="accordion" id="accordionExample">

        <div className="card">
          <div className="card-header" id="headingOne">
            <h3 className="mb-0">
              <a href="#" className="d-block with-icon" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <span className="icon-check"></span> Notifications Preferences
              </a>
            </h3>
          </div>

          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body">
              <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingTwo">
            <h3 className="mb-0">
              <a href="#" className="d-block collapsed with-icon" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                <span className="icon-check"></span> Notifications Preferences #2
              </a>
            </h3>
          </div>

          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div className="card-body">
              <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.</p>
            </div>
          </div>
        </div>

      </div>


      <h3 className="dark-red">Adresses</h3>

      <ul className="list-simple mb-4">
        <li>Une liste classique</li>
        <li>Avec différents items</li>
        <li>Une liste classique</li>
        <li>Avec différents items</li>
      </ul>

      <ul className="list-adresses">
        <li>
          <span className="icon-dealer icon"></span>
          <span className="distance">0 KM</span>
          <h3>NISSAN BRUSSELS</h3>
          <p>CHAUSSÉE DE GAND 1334<br />BERCHEM-SAINT-AGATHE<br />1082</p>
        </li>
        <li>
          <span className="icon-dealer icon"></span>
          <span className="distance">4.7 KM</span>
          <h3>NISSAN BRUSSELS</h3>
          <p>CHAUSSÉE DE GAND 1334<br />BERCHEM-SAINT-AGATHE<br />1082</p>
        </li>
        <li>
          <span className="icon-dealer icon"></span>
          <span className="distance">4.7 KM</span>
          <h3>NISSAN BRUSSELS</h3>
          <p>CHAUSSÉE DE GAND 1334<br />BERCHEM-SAINT-AGATHE<br />1082</p>
        </li>
      </ul>



    </Layout>
  </div>
)

export default StyleGuide
